import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import { Row, Col, ProductBookCard, Button, WhatNext } from '../../components/global'
import 'react-accessible-accordion/dist/fancy-example.css'
import { Link } from 'gatsby'

const AdvancedElectronicsTraining = () => (
  <Layout>
    <SEO title="Advanced Electronics Training Part 1 &amp; 2" />
    <div className="container container-last">
      <h1>Advanced Electronics Training Part 1 &amp; 2</h1>
      <hr />
      <Row>
        <Col width="30%" align="left">
          <ProductBookCard title="Advanced Electronics Training, Part 1 & 2" animate={false} />
        </Col>
        <Col width="68%" align="left">
          <p class="boost">
            Advanced Electronics Training, is a 2-part book that lays the foundation for advanced
            electrical-electronics training following a step-by-step process.
          </p>
          <p>
            <Button
              href="http://www.1shoppingcart.com/SecureCart/SecureCart.aspx?mid=2169DBD4-F088-4CA4-A011-56C1593411AC&pid=2806d388647147a884625fb8122560f8"
              text="Buy Part 1 & 2 Together for $149.00 + shipping"
              type="secondary"
            />
          </p>
          <hr />
          <p>
            <Button
              href="http://www.1shoppingcart.com/SecureCart/SecureCart.aspx?mid=2169DBD4-F088-4CA4-A011-56C1593411AC&pid=a7261f4f390843aeae721b4cfcf20b6b"
              text="Buy Part 1 Only $68.00 + shipping"
              type="primary"
            />
          </p>
          <p class="meta">
            50 pages with over 33 illustrations of schematics and schemations presenting how the
            heart of a vehicle's electrical-electronic system functions with instructions how to
            troubleshooting this circuit.
          </p>
          <p>
            <Button
              href="http://www.1shoppingcart.com/SecureCart/SecureCart.aspx?mid=2169DBD4-F088-4CA4-A011-56C1593411AC&pid=4ef17e8b83e545f7bc59a5e94c2d1ddf"
              text="Buy Part 2 Only $88.00 + shipping"
              type="primary"
            />
          </p>
          <p class="meta">
            Contains 66 Pages with over 89 illustrations, schematics and schemations demonstrating
            how to troubleshoot a vehicle electrical-electronic circuit on any vehicle any time.
            Followed by an 11 Question Review Test.
          </p>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col align="left">
          <h3>What is advanced electronics training?</h3>
          <p class="boost">
            This has become a hot topic of discussion in the vehicle education industry with all the
            new vehicle electronic technologies and electric vehicles rolling off the assembly
            lines. Tech schools are asking what electronics training do we need to offer?
          </p>

          <p>The answer depends on what job you want to do after the training.</p>

          <p>
            What is the goal or objective in seeking advanced electronics training? As a vehicle
            service technician it's pretty obvious that your goal is to become the best tech in your
            shop fixing electronic system problems. You don't want to become an engineer, just be
            the tech that everyone calls on when an electrical-electronic system problem stumps
            everybody else.
          </p>

          <p>
            In our industry today, you sign up for electrical training and sometimes wonder what
            level of electrical training is being offered. Is it going to be too basic or too
            advanced to comprehend and get the maximum benefit?
          </p>

          <p>
            Here's the problem. There are few options for electronics training in the vehicle
            service industry and a lot depends on where you live, when the training is being
            offered, can you invest the time, can you afford it and so on? Is the training what some
            people call Level I, Level II, or Level III? Where do you fit in?
          </p>

          <p>
            It's like trying to assemble a big jigsaw puzzle when all the pieces are piled high on
            the table. Your task is to pick up the pieces and put the puzzle together. Good luck!
          </p>

          <p>
            The advanced electronics training I am offering is not a bunch of pieces that go into
            the puzzle someplace. I am offering advanced electronics training that shows you how to
            put the pieces of the electronics puzzle together into a clear finished picture. Don't
            focus so much on the pieces of the puzzle until you have the "big picture." Then you
            will know where all the pieces (training) you already have fit in the big picture.
          </p>

          <p>
            <strong>Advanced Electronics Training, Part 1</strong>, lays the foundation for advanced
            electrical-electronics training following a step-by-step process. This is accomplished
            by showing you how the electrical-electronics system of a vehicle functions. What
            happens to changes in voltage? How does electron current flow through the electrical
            system and why do I need to know that? How are circuits connected into the
            electrical-electronic system? What are the most important things to consider
            troubleshooting a vehicle's electrical-electronic system problem.
          </p>

          <p>
            Part 1 covers electrical system troubleshooting procedures for any make or model
            vehicle, whether it's automotive, truck, or heavy duty equipment. This training also
            applies to watercraft, motorcycle and aircraft electrical systems. They all follow the
            same electrical-electronic system pattern. If you understand this system pattern you are
            beginning to learn how to troubleshoot any electrical-electronic circuit on any type of
            vehicle you may encounter. Part 1 of this new series gives you an overall picture of a
            vehicle's electrical-electronic system so you will know how to arrange all the pieces of
            the electrical (training) puzzle you have acquired thus far.
          </p>

          <p>
            Vehicle electronics is like a giant puzzle to put together with many little pieces.{' '}
            <strong>ADVANCED ELECTRONICS TRAINING, Part 2</strong>, focuses on the latest pieces
            that go into the puzzle providing an understanding of the newest electronic circuits
            being added to new vehicles each model year.
          </p>
          <p>
            As vehicles become more complex, additional electronics training is required emphasizing
            how to test and troubleshoot these electronic circuits. If the assembly of your puzzle
            has a good beginning with ADVANCED ELECTRONICS TRAINING Part 1 , adding these additional
            electronic pieces (circuits) to the puzzle with{' '}
            <strong>ADVANCED ELECTRONICS TRAINING, Part 2</strong>, becomes a lot easier.
          </p>
          <p>
            The objective is to test, troubleshoot and fix any electronic circuit on any vehicle
            regardless of make and model. Part 2 walks you through various advanced electronic
            circuit principles with testing and troubleshooting techniques these circuits and
            systems require.
          </p>

          <p class="boost">
            Become the tech everyone turns to when an electronic system problem stumps everyone
            else.
          </p>
        </Col>
      </Row>
      <hr />
      <WhatNext />
    </div>
  </Layout>
)

export default AdvancedElectronicsTraining
